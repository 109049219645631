import React from "react";
import Carousel from "../components/carousel/Carousel";
import Department from "../components/department/Department";
import HomeCard from "../components/homeCard/homeCard";
import MobileHomeButtonGroup from "../components/mobileHome/mobileHomeButtonGroup";


import DoctorImage from "../resources/doctor.png";
import MenuImage from "../resources/menu.png";
import BottomViewImage from "../resources/bottom-view.png";

import introduce7Image from "../resources/introduce/4-1.jpg";

export default class HomeContainer extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (window.daum) {
      try {
        var markerPosition = new window.daum.maps.LatLng(35.840521, 128.707468);
        var options = {
          center: new window.daum.maps.LatLng(35.840521, 128.707468),
          level: 4,
        };
        var container = document.getElementById("map");
        var map = new window.daum.maps.Map(container, options);
        var marker = new window.daum.maps.Marker({ position: markerPosition });
        var infowindow = new window.daum.maps.InfoWindow({
          content:
            '<div style="width:150px;text-align:center;font-size: 0.8rem;padding-top:5px;padding-bottom:5px">푸른청신경과(2층)</div>',
        });
        infowindow.open(map, marker);
        marker.setMap(map);
      } catch (e) {
        // no-op
      }
    }
  }

  render() {
    return (
      <div>
        <Carousel />
        <MobileHomeButtonGroup />
        <div
          className="w-100 my-4 d-sm-block d-none"
          style={{ height: "20px" }}
        />
        <HomeCard />
        <div
          className="w-100 my-4 d-sm-block d-none"
          style={{ height: "20px" }}
        />
        <div className="homecard-container row" style={{}}>
          <div className="col-md-6 p-0 p-md-3">
            <a href="/introduce/1">
              <img
                alt="doc"
                className="img-fluid col-sm-12 p-md-0 pb-2"
                src={DoctorImage}
              />
            </a>
          </div>
          <div className="col-md-6 p-0 p-md-3">
            <a href="/introduce/4">
              <img
                alt="menu"
                className="img-fluid col-sm-12 p-md-0 py-2"
                src={MenuImage}
              />
            </a>
          </div>
        </div>
        <div
          className="w-100 my-4 d-sm-block d-none"
          style={{ height: "20px" }}
        />
        <div className="col-12 d-block d-md-none">
          <img alt="home-bottom" className="img-fluid" src={BottomViewImage} />
        </div>
        <div className="w-100 d-block d-xl-none" style={{ height: "20px" }} />
        <div className="col-sm-12 col-xl-7 d-block home-map-container mx-auto p-0">
          <div id="map" className="col-12" style={{ height: "400px" }} />
        </div>
        <div className="w-100 d-block d-md-none" style={{ height: "20px" }} />
        <div
          className="w-100 my-4 d-sm-block d-none"
          style={{ height: "20px" }}
        />
        <div className="home-department">
          <Department />
        </div>
        <div
          className="w-100 my-4 d-sm-block d-none"
          style={{ height: "20px" }}
        />

        <div id="fee" >
          <a href="/introduce/4#fee-table" className="col-md-2 col-sm-2">
            <img className="img-fluid" style={{ marginLeft: 'auto'}} src={introduce7Image} alt="비급여항목" />
          </a>
        </div>

        <div
          className="w-100 my-4 d-sm-block d-none"
          style={{ height: "20px" }}
        />
      </div>
    );
  }
}
